import { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import Querie_report from '../components/Querie_report4';

const View_test_records = () => {
  const [items, setitems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderBy, setOrderBy] = useState('created_at');

  useEffect(() => {
    const fetchitems = async () => {
      try {
        const { data, error } = await supabase
          .from('test_record')
          .select('*')
          .order(orderBy, { ascending: false });

        if (error) throw error;
        setitems(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchitems();
  }, [orderBy]);

  // Handle delete action
  const handleDelete = (id) => {
    setitems(previtems => previtems.filter(item => item.id !== id));
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error">Error: {error}</p>;

  return (
    <div className="page home">
      <div className="order-by">
        <p>Order by:</p>
        <button onClick={() => setOrderBy('created_at')}>Created at</button>
        <button onClick={() => setOrderBy('date')}>Date</button>
      </div>
      <div className="smoothie-grid">
        {items.length === 0 ? (
          <p>No records found.</p>
        ) : (
          items.map(item => (
            <Querie_report key={item.id} item={item} onDelete={handleDelete} />
          ))
        )}
      </div>
    </div>
  );
};

export default View_test_records;
