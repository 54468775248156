import React from 'react';

const Querie_report = ({ item }) => {
  return (
    <div className="smoothie-card">
      <h3>Date:</h3>
      <p>{item.date}</p>
      <h3>Value:</h3>
      <p>{item.value}</p>
    </div>
  );
};

export default Querie_report;
