/*
import React, { useEffect, useState } from 'react';
import supabase from "../config/supabaseClient";
import '../index.css';  

const PracticeDetailPage = ({ practiceId, onBack }) => {
  const [practice, setPractice] = useState([]);
  const [presentCount, setPresentCount] = useState(0);
  const [medicalDepartmentCount, setMedicalDepartmentCount] = useState(0);
  const [justifiedAbsenceCount, setJustifiedAbsenceCount] = useState(0);
  const [absenceCount, setAbsenceCount] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPracticeDetails = async () => {
      try {
        // Fetch practice details
        const { data, error } = await supabase
          .rpc('get_practice_detailes', { practice_id_param: practiceId });

        if (error) {
          throw error;
        }

        if (data.length > 0) {
          setPractice(data);

          // Fetch counts for each status type
          const { count: presentCount, error: presentCountError } = await supabase
            .from('practices_presence')
            .select('id', { count: 'exact' })
            .eq('practice_id', practiceId)
            .eq('status', 'Present');

          if (presentCountError) {
            throw presentCountError;
          }
          setPresentCount(presentCount);

          const { count: medicalDepartmentCount, error: medicalDeptError } = await supabase
            .from('practices_presence')
            .select('id', { count: 'exact' })
            .eq('practice_id', practiceId)
            .eq('status', 'Medical Department');

          if (medicalDeptError) {
            throw medicalDeptError;
          }
          setMedicalDepartmentCount(medicalDepartmentCount);

          const { count: justifiedAbsenceCount, error: justifiedAbsenceError } = await supabase
            .from('practices_presence')
            .select('id', { count: 'exact' })
            .eq('practice_id', practiceId)
            .eq('status', 'Justified Absence');

          if (justifiedAbsenceError) {
            throw justifiedAbsenceError;
          }
          setJustifiedAbsenceCount(justifiedAbsenceCount);

          const { count: absenceCount, error: absenceError } = await supabase
            .from('practices_presence')
            .select('id', { count: 'exact' })
            .eq('practice_id', practiceId)
            .eq('status', 'Absence');

          if (absenceError) {
            throw absenceError;
          }
          setAbsenceCount(absenceCount);

        } else {
          setError('No practice details found.');
        }
      } catch (error) {
        setError('Error fetching practice details: ' + (error.message || 'Unknown error'));
      }
    };

    fetchPracticeDetails();
  }, [practiceId]);

  // Calculate total and percentages
  const totalCount = presentCount + medicalDepartmentCount + justifiedAbsenceCount + absenceCount;
  const presentPercentage = totalCount ? ((presentCount / totalCount) * 100).toFixed(2) : 0;
  const medicalDepartmentPercentage = totalCount ? ((medicalDepartmentCount / totalCount) * 100).toFixed(2) : 0;
  const justifiedAbsencePercentage = totalCount ? ((justifiedAbsenceCount / totalCount) * 100).toFixed(2) : 0;
  const absencePercentage = totalCount ? ((absenceCount / totalCount) * 100).toFixed(2) : 0;

  return (
    <div className="page practice-detail">
      <button onClick={onBack}>Back to Practices</button>
      {error && (<p className="error">{error}</p>)}
      {practice.length > 0 && (
        <div>
          <div className="practice-info">
            <h2>Practice Details</h2>
            <p><strong>Practice ID:</strong> {practice[0].practice_id}</p>
            <p><strong>Date:</strong> {practice[0].practice_date}</p>
            <p><strong>Journey:</strong> {practice[0].practice_journey}</p>
            <p><strong>Time:</strong> {practice[0].practice_time}</p>
            <p><strong>Weather:</strong> {practice[0].practice_weather}</p>
            <p><strong>Total Present Count:</strong> {presentCount}</p>
            <p><strong>Medical Department Count:</strong> {medicalDepartmentCount}</p>
            <p><strong>Justified Absence Count:</strong> {justifiedAbsenceCount}</p>
            <p><strong>Absence Count:</strong> {absenceCount}</p>
            <p><strong>Present Percentage:</strong> {presentPercentage}%</p>

          </div>
          <h3>Players</h3>
          {practice.map((detail, index) => (
            <div key={index} className="player-details">
              <p><strong>Player Name:</strong> {detail.player_name}</p>
              <p><strong>Presence Status:</strong> {detail.presence_status}</p>
              <hr />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PracticeDetailPage;
*/

import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";
import '../index.css';

const PracticeDetailPage = ({ practiceId, onBack }) => {
  const [practice, setPractice] = useState([]);
  const [presentCount, setPresentCount] = useState(0);
  const [medicalDepartmentCount, setMedicalDepartmentCount] = useState(0);
  const [justifiedAbsenceCount, setJustifiedAbsenceCount] = useState(0);
  const [absenceCount, setAbsenceCount] = useState(0);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPracticeDetails = async () => {
      try {
        const { data, error } = await supabase
          .rpc('get_practice_detailes', { practice_id_param: practiceId });

        if (error) {
          throw error;
        }

        if (data.length > 0) {
          setPractice(data);

          const { count: presentCount, error: presentCountError } = await supabase
            .from('practices_presence')
            .select('id', { count: 'exact' })
            .eq('practice_id', practiceId)
            .eq('status', 'Present');

          if (presentCountError) {
            throw presentCountError;
          }
          setPresentCount(presentCount);

          const { count: medicalDepartmentCount, error: medicalDeptError } = await supabase
            .from('practices_presence')
            .select('id', { count: 'exact' })
            .eq('practice_id', practiceId)
            .eq('status', 'Medical Department');

          if (medicalDeptError) {
            throw medicalDeptError;
          }
          setMedicalDepartmentCount(medicalDepartmentCount);

          const { count: justifiedAbsenceCount, error: justifiedAbsenceError } = await supabase
            .from('practices_presence')
            .select('id', { count: 'exact' })
            .eq('practice_id', practiceId)
            .eq('status', 'Justified Absence');

          if (justifiedAbsenceError) {
            throw justifiedAbsenceError;
          }
          setJustifiedAbsenceCount(justifiedAbsenceCount);

          const { count: absenceCount, error: absenceError } = await supabase
            .from('practices_presence')
            .select('id', { count: 'exact' })
            .eq('practice_id', practiceId)
            .eq('status', 'Absence');

          if (absenceError) {
            throw absenceError;
          }
          setAbsenceCount(absenceCount);

        } else {
          setError('No practice details found.');
        }
      } catch (error) {
        setError('Error fetching practice details: ' + (error.message || 'Unknown error'));
      }
    };

    fetchPracticeDetails();
  }, [practiceId]);

  const totalCount = presentCount + medicalDepartmentCount + justifiedAbsenceCount + absenceCount;
  const presentPercentage = totalCount ? ((presentCount / totalCount) * 100).toFixed(2) : 0;
  const medicalDepartmentPercentage = totalCount ? ((medicalDepartmentCount / totalCount) * 100).toFixed(2) : 0;
  const justifiedAbsencePercentage = totalCount ? ((justifiedAbsenceCount / totalCount) * 100).toFixed(2) : 0;
  const absencePercentage = totalCount ? ((absenceCount / totalCount) * 100).toFixed(2) : 0;

  const handleAddExercises = () => {
    navigate(`/add-exercises/${practiceId}`);
  };

  const handleViewExercises = () => {
    navigate(`/exercises-list/${practiceId}`);
  };

  return (
    <div className="page practice-detail">
      <button onClick={onBack}>Back to Practices</button>
      {error && (<p className="error">{error}</p>)}
      {practice.length > 0 && (
        <div>
          <div className="practice-info">
            <h2>Practice Details</h2>
            <p><strong>Practice ID:</strong> {practice[0].practice_id}</p>
            <p><strong>Date:</strong> {practice[0].practice_date}</p>
            <p><strong>Journey:</strong> {practice[0].practice_journey}</p>
            <p><strong>Time:</strong> {practice[0].practice_time}</p>
            <p><strong>Weather:</strong> {practice[0].practice_weather}</p>
            <p><strong>Total Present Count:</strong> {presentCount}</p>
            <p><strong>Medical Department Count:</strong> {medicalDepartmentCount}</p>
            <p><strong>Justified Absence Count:</strong> {justifiedAbsenceCount}</p>
            <p><strong>Absence Count:</strong> {absenceCount}</p>
            <p><strong>Present Percentage:</strong> {presentPercentage}%</p>
          </div>
          <h3>Players</h3>
          {practice.map((detail, index) => (
            <div key={index} className="player-details">
              <p><strong>Player Name:</strong> {detail.player_name}</p>
              <p><strong>Presence Status:</strong> {detail.presence_status}</p>
              <hr />
            </div>
          ))}

          {/* Add Exercises Button */}
          <button onClick={handleAddExercises}>Add Exercises</button>

          {/* View Exercises Button */}
          <button onClick={handleViewExercises}>View Exercises</button>
        </div>
      )}
    </div>
  );
};

export default PracticeDetailPage;
