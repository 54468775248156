/*
import { BrowserRouter, Routes, Route, Link, useLocation } from "react-router-dom";

// pages
import Login from "./pages/login";
import View from "./pages/View";
import Create from "./pages/Create";
import Update from "./pages/Update";
import Home from "./pages/Home";
import Filter2 from "./pages/Filter2";
import Players from "./pages/Players"
import Filter_report from "./pages/Filter_report";
import Medical from "./pages/Medical";
import Name_report from "./pages/Name_report";
import Create_record from "./pages/Create_record";
import Practices from "./pages/Practices";
import UpdateUserId from "./pages/UpdateUserId";
import Admin from "./pages/Admin";
import Create_practice from "./pages/Create_practice";
import ViewPractices from "./pages/view_practices";
import PracticeDetailPage from "./pages/PracticeDetailPage";
import PracticePresencePage from "./pages/PracticePresencePage";
import Create2 from "./pages/create2";
import ViewTalkArchive from "./pages/viewtalkarchive";
import Talk from "./pages/talk";
import Create_Internal_Scouting from "./pages/create_internal_scouting";
import View_Internal_Scouting from "./pages/view_internal_scouting";
import Create_External_Scouting from "./pages/create_external_scouting";
import View_External_Scouting from "./pages/view_external_scouting";
import Scouting from "./pages/Scouting";
import ImageConverter from "./pages/ImageConverter";
import Create_test from "./pages/create_test";
import View_test from "./pages/view_test";
import Create_Test_Record from "./pages/create_test_record";

function AppContent() {
  const location = useLocation();
  
  return (
    <>
      {location.pathname !== "/" && location.pathname !== "/home" && location.pathname !== "/players" && location.pathname !== "/medical" && location.pathname !== "/practices" && location.pathname !== "/admin" && location.pathname !== "/talk" && location.pathname !== "/scouting" && (
        <nav>
          <h1>Players Database</h1>
          <Link to="/home">Home</Link>
          <Link to="/players">Players</Link>
          <Link to="/medical">Medical</Link>
          <Link to="/practices">Practices</Link>
          <Link to="/talk">Talk Archive</Link>
          <Link to="/scouting">Scouting</Link>
          <Link to="/admin">Admin</Link>
          <Link to="/">Log-out</Link>
          <Link to="/image-converter">Image Converter</Link>
          <Link to="/create_test">Create Test</Link>
          <Link to="/view_test">View Test</Link>
          <Link to="/create_test_record">Create Test Record</Link>
        </nav>
      )}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/view" element={<View />} />
        <Route path="/create" element={<Create />} />
        <Route path="/:id" element={<Update />} />
        <Route path="/filter2" element={<Filter2 />} />
        <Route path="/Players" element={<Players />} />
        <Route path="/Filter_report" element={<Filter_report />} />
        <Route path="/Medical" element={<Medical />} />
        <Route path="/name_report" element={<Name_report />} />
        <Route path="/Create_record" element={<Create_record />} />
        <Route path="/Practices" element={<Practices />} />
        <Route path="/UpdateUserId" element={<UpdateUserId />} />
        <Route path="/Admin" element={<Admin />} />
        <Route path="/Create_practice" element={<Create_practice />} />
        <Route path="/view_practices" element={<ViewPractices />} />
        <Route path="/practices/:id" element={<PracticeDetailPage />} />
        <Route path="/practice-presence" element={<PracticePresencePage />} />
        <Route path="/create2" element={<Create2 />} />
        <Route path="/viewtalkarchive" element={<ViewTalkArchive />} />
        <Route path="/talk" element={<Talk />} />
        <Route path="/create_internal_scouting" element={<Create_Internal_Scouting />} />
        <Route path="/view_internal_scouting" element={<View_Internal_Scouting />} />
        <Route path="/create_external_scouting" element={<Create_External_Scouting />} />
        <Route path="/view_external_scouting" element={<View_External_Scouting />} />
        <Route path="/scouting" element={<Scouting />} />
        <Route path="/image-converter" element={<ImageConverter />} />
        <Route path="/create_test" element={<Create_test />} />
        <Route path="/view_test" element={<View_test />} />
        <Route path="/create_test_record" element={<Create_Test_Record />} />
      </Routes>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

export default App;
*/

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './NavBar'; // Import the NavBar component
import Login from './pages/login';
import View from './pages/View';
import Create from './pages/Create';
import Update from './pages/Update';
import Home from './pages/Home';
import Filter2 from './pages/Filter2';
import Players from './pages/Players';
import Filter_report from './pages/Filter_report';
import Medical from './pages/Medical';
import Name_report from './pages/Name_report';
import Create_record from './pages/Create_record';
import Practices from './pages/Practices';
import UpdateUserId from './pages/UpdateUserId';
import Admin from './pages/Admin';
import Create_practice from './pages/Create_practice';
import ViewPractices from './pages/view_practices';
import PracticeDetailPage from './pages/PracticeDetailPage';
import PracticePresencePage from './pages/PracticePresencePage';
import Create2 from './pages/create2';
import ViewTalkArchive from './pages/viewtalkarchive';
import Talk from './pages/talk';
import Create_Internal_Scouting from './pages/create_internal_scouting';
import View_Internal_Scouting from './pages/view_internal_scouting';
import Create_External_Scouting from './pages/create_external_scouting';
import View_External_Scouting from './pages/view_external_scouting';
import Scouting from './pages/Scouting';
import ImageConverter from './pages/ImageConverter';
import Create_test from './pages/create_test';
import View_test from './pages/view_test';
import Create_Test_Record from './pages/create_test_record';
import Filter_test from './pages/filter_test';
import Tests from './pages/Tests';
import View_test_records from './pages/view_test_records';
import Create_adversarie from './pages/create_adversarie';
import Create_game from './pages/create_game';
import ViewGames from './pages/ViewGames';
import GameDetailPage from './components/GameDetailPage';
import AddCallsPage from './pages/AddCallsPage';
import ManagePlayerStatusPage from './pages/ManagePlayerStatusPage';
import ManagePlayerStatusesPage from './pages/ManagePlayerStatusesPage';
import InsertGameMinutesPage from './pages/InsertGameMinutesPage';
import InsertGameEventsPage from './pages/InsertGameEventsPage';
import Games from './pages/Games';
import PlayerDetails from './pages/PlayerDetails';
import MedicalReports from './pages/MedicalReports';
import GameEvents from './pages/GameEvents';
import TestRecords from './pages/TestRecords';
import AllPracticesPresence from './pages/AllPracticesPresence';
import InternalScouting from './pages/InternalScouting';
import TalkArchive from './pages/TalkArchive';
import InsertGameRatings from './pages/InsertGameRatings';
import TacticalBoard from './taticalboard/TacticalBoard';
import UploadImage from './pages/UploadImage';
import DisplayImages from './pages/DisplayImages';
import AddUserToGroup from './pages/AddUserToGroup';
import UploadExercise from './pages/UploadExercise';
import ExerciseDetails from './pages/ExerciseDetails';
import DisplayExercises from './pages/DisplayExercises';
import Exercises from './pages/Exercises';
import SearchExercises from './pages/SearchExercises';
import AddExercisesPage from './pages/AddExercisesPage';
import ExercisesListPage from './pages/ExercisesListPage';
import InsertGameResultPage from './pages/InsertGameResultPage';
import GpxUploader from './pages/GpxUploader';
import Profile from './pages/Profile';
import SignUpWithEmail from './pages/SignUpWithEmail';
import SetupPassword from './pages/SetupPassword';

function App() {
  return (
    <BrowserRouter>
      <div className="app-container">
        <NavBar /> {/* Include the NavBar component */}
        <div className="content">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/view" element={<View />} />
            <Route path="/create" element={<Create />} />
            <Route path="/:id" element={<Update />} />
            <Route path="/filter2" element={<Filter2 />} />
            <Route path="/players" element={<Players />} />
            <Route path="/filter_report" element={<Filter_report />} />
            <Route path="/medical" element={<Medical />} />
            <Route path="/name_report" element={<Name_report />} />
            <Route path="/create_record" element={<Create_record />} />
            <Route path="/practices" element={<Practices />} />
            <Route path="/updateUserId" element={<UpdateUserId />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/create_practice" element={<Create_practice />} />
            <Route path="/view_practices" element={<ViewPractices />} />
            <Route path="/practices/:id" element={<PracticeDetailPage />} />
            <Route path="/practice-presence" element={<PracticePresencePage />} />
            <Route path="/create2" element={<Create2 />} />
            <Route path="/viewtalkarchive" element={<ViewTalkArchive />} />
            <Route path="/talk" element={<Talk />} />
            <Route path="/create_internal_scouting" element={<Create_Internal_Scouting />} />
            <Route path="/view_internal_scouting" element={<View_Internal_Scouting />} />
            <Route path="/create_external_scouting" element={<Create_External_Scouting />} />
            <Route path="/view_external_scouting" element={<View_External_Scouting />} />
            <Route path="/scouting" element={<Scouting />} />
            <Route path="/image-converter" element={<ImageConverter />} />
            <Route path="/create_test" element={<Create_test />} />
            <Route path="/view_test" element={<View_test />} />
            <Route path="/create_test_record" element={<Create_Test_Record />} />
            <Route path="/filter_test" element={<Filter_test />} />
            <Route path="/tests" element={<Tests />} />
            <Route path="/view_test_records" element={<View_test_records />} />
            <Route path="/create_adversarie" element={<Create_adversarie />} />
            <Route path="/create_game" element={<Create_game />} />
            <Route path="/view_games" element={<ViewGames />} />
            <Route path="/games/:id" element={<GameDetailPage />} />
            <Route path="/add-calls" element={<AddCallsPage />} />
            <Route path="/manage-player-status" element={<ManagePlayerStatusPage />} />
            <Route path="/manage-player-statuses" element={<ManagePlayerStatusesPage />} />
            <Route path="/insert-game-minutes" element={<InsertGameMinutesPage />} />
            <Route path="/insert-game-events" element={<InsertGameEventsPage />} />
            <Route path="/games" element={<Games />} />
            <Route path="/player/:id" element={<PlayerDetails />} />
            <Route path="/medical-reports/:id" element={<MedicalReports />} />
            <Route path="/game-events/:id" element={<GameEvents />} />
            <Route path="/test-records/:id" element={<TestRecords />} />
            <Route path="/all-practices-presence/:id" element={<AllPracticesPresence />} />
            <Route path="/internal-scouting/:id" element={<InternalScouting />} />
            <Route path="/talk-archive/:id" element={<TalkArchive />} />
            <Route path="/insert-game-ratings" element={<InsertGameRatings />} />
            <Route path="/tactical-board" element={<TacticalBoard />} />
            <Route path="/upload-image" element={<UploadImage />} />
            <Route path="/display-images" element={<DisplayImages />} />
            <Route path="/add-user-to-group" element={<AddUserToGroup />} />
            <Route path="/upload-exercise" element={<UploadExercise />} />
            <Route path="/exercise-details/:id" element={<ExerciseDetails />} />
            <Route path="/display-exercises" element={<DisplayExercises />} />
            <Route path="/exercises" element={<Exercises />} />
            <Route path="/search-exercises" element={<SearchExercises />} />
            <Route path="/add-exercises/:practiceId" element={<AddExercisesPage />} />
            <Route path="/exercises-list/:practiceId" element={<ExercisesListPage />} />
            <Route path="/insert-game-result/:gameId" element={<InsertGameResultPage />} />
            <Route path="/gpx-uploader" element={<GpxUploader />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/sign-up-with-email" element={<SignUpWithEmail />} />
            <Route path="/setup-password" element={<SetupPassword />} />
            </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;

