/*
// NavBar.js
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './NavBar.css'; // Import your CSS file

function NavBar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation();
  
  const toggleNav = () => {
    setIsNavOpen(prev => !prev);
  };

  // Hide menu on the '/' route
  const isMenuVisible = location.pathname !== '/';

  return (
    <>
      {isMenuVisible && (
        <button className="nav-toggle" onClick={toggleNav}>
          {isNavOpen ? 'Menu' : 'Menu'}
        </button>
      )}
      {isMenuVisible && (
        <nav className={`nav-menu ${isNavOpen ? 'show' : ''}`}>
          <button className="nav-close" onClick={toggleNav}>✕</button>
          <ul>
            <li><a href="/home">Home</a></li>
            <li><a href="/players">Players</a></li>
            <li><a href="/medical">Medical</a></li>
            <li><a href="/practices">Practices</a></li>
            <li><a href="/talk">Talk Archive</a></li>
            <li><a href="/scouting">Scouting</a></li>
            <li><a href="/tests">Tests</a></li>
            <li><a href="/games">Games</a></li>
            <li><a href="/create_adversarie">Create Adversarie</a></li>
            <li><a href="/exercises">Exercises</a></li>
            <li><a href="/admin">Admin</a></li>
            <li><a href="/">Log-out</a></li>
            <li><a href="/image-converter">Image Converter</a></li>
            <li><a href="/tactical-board">Tactical Board</a></li>
            <li><a href="/UploadImages">Upload Images</a></li>
            <li><a href="/display-images">Display Images</a></li>
            <li><a href="/add-user-to-group">Add User to Group</a></li>
            <li><a href="/gpx-uploader">GPX Uploader</a></li>
            <lil><a href="/profile">Your Profile</a>  </lil>

          </ul>
        </nav>
      )}
    </>
  );
}

export default NavBar;
*/

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

function NavBar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation();
  
  const toggleNav = () => {
    setIsNavOpen(prev => !prev);
  };

  // Hide menu on the '/' route
  const isMenuVisible = location.pathname !== '/';

  const styles = {
    topBar: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      backgroundColor: '#333', // Adjust background color as needed
      color: 'white',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
      zIndex: 1000, // Ensure the top bar is above other content
    },
    mainContent: {
      paddingTop: '60px', // Adjust this value to match the height of the top bar
    },
    navToggle: {
      background: 'none',
      border: 'none',
      color: 'white',
      fontSize: '16px',
      cursor: 'pointer',
    },
    navMenu: {
      position: 'fixed',
      top: '60px', // Adjust based on the height of the top bar
      right: 0,
      width: '250px', // Adjust width as needed
      height: 'calc(100% - 60px)', // Adjust based on the height of the top bar
      backgroundColor: '#444', // Adjust background color as needed
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '10px',
      boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.5)',
      transform: 'translateX(100%)',
      transition: 'transform 0.3s ease',
      zIndex: 1000, // Ensure the nav menu is above other content
      overflowY: 'auto', // Make the menu scrollable
    },
    navMenuShow: {
      transform: 'translateX(0)',
    },
    navClose: {
      background: 'none',
      border: 'none',
      color: 'white',
      fontSize: '24px',
      cursor: 'pointer',
      alignSelf: 'flex-end',
      padding: '10px',
    },
    navMenuList: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
    navMenuItem: {
      margin: '20px 0',
    },
    navMenuLink: {
      color: 'white',
      textDecoration: 'none',
      fontSize: '18px',
    },
  };

  return (
    <>
      {isMenuVisible && (
        <div style={styles.topBar}>
          <button style={styles.navToggle} onClick={toggleNav}>
            {isNavOpen ? 'Close' : 'Menu'}
          </button>
        </div>
      )}
      {isMenuVisible && (
        <nav style={{ ...styles.navMenu, ...(isNavOpen ? styles.navMenuShow : {}) }}>
          <button style={styles.navClose} onClick={toggleNav}>✕</button>
          <ul style={styles.navMenuList}>
            <li style={styles.navMenuItem}><a href="/home" style={styles.navMenuLink}>Home</a></li>
            <li style={styles.navMenuItem}><a href="/players" style={styles.navMenuLink}>Players</a></li>
            <li style={styles.navMenuItem}><a href="/medical" style={styles.navMenuLink}>Medical</a></li>
            <li style={styles.navMenuItem}><a href="/practices" style={styles.navMenuLink}>Practices</a></li>
            <li style={styles.navMenuItem}><a href="/talk" style={styles.navMenuLink}>Talk Archive</a></li>
            <li style={styles.navMenuItem}><a href="/scouting" style={styles.navMenuLink}>Scouting</a></li>
            <li style={styles.navMenuItem}><a href="/tests" style={styles.navMenuLink}>Tests</a></li>
            <li style={styles.navMenuItem}><a href="/games" style={styles.navMenuLink}>Games</a></li>
            <li style={styles.navMenuItem}><a href="/create_adversarie" style={styles.navMenuLink}>Create Adversarie</a></li>
            <li style={styles.navMenuItem}><a href="/exercises" style={styles.navMenuLink}>Exercises</a></li>
            <li style={styles.navMenuItem}><a href="/admin" style={styles.navMenuLink}>Admin</a></li>
            <li style={styles.navMenuItem}><a href="/" style={styles.navMenuLink}>Log-out</a></li>
            <li style={styles.navMenuItem}><a href="/image-converter" style={styles.navMenuLink}>Image Converter</a></li>
            <li style={styles.navMenuItem}><a href="/tactical-board" style={styles.navMenuLink}>Tactical Board</a></li>
            <li style={styles.navMenuItem}><a href="/UploadImages" style={styles.navMenuLink}>Upload Images</a></li>
            <li style={styles.navMenuItem}><a href="/display-images" style={styles.navMenuLink}>Display Images</a></li>
            <li style={styles.navMenuItem}><a href="/add-user-to-group" style={styles.navMenuLink}>Add User to Group</a></li>
            <li style={styles.navMenuItem}><a href="/gpx-uploader" style={styles.navMenuLink}>GPX Uploader</a></li>
            <li style={styles.navMenuItem}><a href="/profile" style={styles.navMenuLink}>Your Profile</a></li>
          </ul>
        </nav>
      )}
      <div style={styles.mainContent}>
        {/* Your page content goes here */}
      </div>
    </>
  );
}

export default NavBar;
